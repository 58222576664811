import { isLocalhost, isSafari } from "@utils/browser";
import { ReactElement, useEffect, useState } from "react";
import IFrame from "../IFrame";
import { JWTIFrameWrapper } from "./JWTIFrame.styled";
import { IJWTIFrameProps } from "./types";

export default function JWTIFrame({
  iFrameStyles = {},
  location,
  frame,
  postMessageData,
}: IJWTIFrameProps): ReactElement {
  const { horPadding, height, mdHeight, xlHeight, xlRatio, fullHeight } =
    iFrameStyles;

  const [urlIsLocalhost, setUrlIsLocalhost] = useState<boolean>();
  const [browserIsSafari, setBrowserIsSafari] = useState<boolean>();
  useEffect(() => {
    setUrlIsLocalhost(isLocalhost());
    setBrowserIsSafari(isSafari());
  }, []);

  if (
    typeof urlIsLocalhost !== "boolean" ||
    typeof browserIsSafari !== "boolean"
  ) {
    return <></>;
  }

  if (urlIsLocalhost && browserIsSafari) {
    return (
      <JWTIFrameWrapper
        horPadding={horPadding}
        height={height}
        mdHeight={mdHeight}
        xlHeight={xlHeight}
        xlRatio={xlRatio}
        fullHeight={fullHeight}
      >
        <p>⚠️ Embedding is not supported in Safari and iOS on localhost.</p>
      </JWTIFrameWrapper>
    );
  }

  return (
    <IFrame
      src="https://appytown.metabaseapp.com/public/dashboard/ea8c2738-2518-422f-b497-d21d5418a166"
      location={location}
      frame={frame}
      postMessageData={postMessageData}
      horPadding={horPadding}
      height={height}
      mdHeight={mdHeight}
      xlHeight={xlHeight}
      xlRatio={xlRatio}
      fullHeight={fullHeight}
    />
  );
}
