import styled from "styled-components";

import { mediaMax } from "@constants/theme/utils";

export const DeveloperDashboard = styled.div`
  background-color: ${({ theme }) => theme.colors.blue.mediumDark};
  border-radius: 32px;
  padding: 32px 64px 64px;

  h1 {
    color: ${({ theme }) => theme.colors.blue.dark};
    font-size: 64px;
    font-weight: 900;
    letter-spacing: 0.3px;
    text-align: center;
  }

  ${mediaMax("sm")`
    margin: 24px;
    padding: 16px;

    h1 {
      font-size: 32px;
    }
  `}
`;
