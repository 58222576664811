import { ReactElement } from "react";

import { APP_STYLES } from "@constants/metabase";
import PageContainer from "@components/container/PageContainer";
import JWTIFrame from "@components/thirdParty/metabase/JWTIFrame";
import { DeveloperDashboard } from "@components/ui/DeveloperDashboard/DeveloperDashboard.styled";

export default function IndexPage(): ReactElement {
  return (
    <PageContainer>
      <DeveloperDashboard>
        <h1>How are our apps doing?</h1>
        <JWTIFrame
          returnTo="/dashboard/1-overview"
          appStyles={APP_STYLES}
          iFrameStyles={{
            height: 1592,
            mdHeight: 776,
            xlRatio: 1429 / 800,
          }}
        />
      </DeveloperDashboard>
    </PageContainer>
  );
}
